import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = ({ data }) => {
  const { edges } = data.postList
  return (
    <Layout>
      <Seo title="Home" />
      <div className="container mx-auto">
        {edges.map(edge => {
          const { id, slug, excerpt, frontmatter } = edge.node
          const { title, tags, createdAt } = frontmatter
          return (
            <Link
              to={slug}
              key={id}
              className="block p-4 active:bg-zinc-100 lg:active:bg-zinc-200 lg:hover:bg-zinc-100"
            >
              <div className="text-black text-xl font-bold">{title}</div>
              <div className="text-xs sm:text-sm md:text-base text-zinc-800">
                {excerpt}
              </div>
              <div className="text-xs sm:text-sm md:text-base text-zinc-500 flex">
                <div>{createdAt}</div>
                <div className="px-2">{tags.join(", ")}</div>
              </div>
            </Link>
          )
        })}
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        description
      }
    }
    postList: allMdx(sort: { order: DESC, fields: frontmatter___createdAt }) {
      edges {
        node {
          slug
          excerpt(truncate: true)
          id
          frontmatter {
            title
            tags
            createdAt(formatString: "YYYY-MM-DD")
          }
        }
      }
    }
  }
`
